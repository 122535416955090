.skrivnostne-razlike {
  .game-container {
    .svg-container {
      background: url("/assets/images/games/skrivnostne-razlike/bg-razlike.jpg");
      display: inline-block;
      position: relative;
      margin: 5px;

      .feedback {
        &.clicked:after {
          animation: anim-effect 0.3s forwards;
        }

        &.found:after {
          animation: anim-effect-found 0.3s forwards;
        }
      }

      /*       circle[class*="target"] {
        transform: scale(0.9, 0.9);
        transition: opacity 1.6s ease-out 0s, transform 7.2s linear 0s;
        transform-origin: center center;
      } */

      /*       .feedback {
        position: absolute;

        &:after {
          background: rgba(182, 111, 132, 0.8);
          display: inline-block;
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          opacity: 0;
        }

        &.clicked:after {
          animation: anim-effect 0.3s forwards;
        }

        &.found:after {
          animation: anim-effect-found 0.3s forwards;
        }
      } */
    }

    .image {
      position: relative;
      svg {
        cursor: pointer;
        width: 450px;
        height: 334px;

        @include media-breakpoint-down(sm) {
          width: 310px;
          height: 230px;
        }

        #left {
          g.notfound {
            circle {
              opacity: 0;
            }
          }
          g.found {
            circle {
              pointer-events: none;
              opacity: 1;
            }
          }
        }

        #right {
          g.notfound {
            rect,
            circle {
              opacity: 0;
            }
          }
          g.found {
            rect,
            circle {
              opacity: 1;
            }
          }
        }
      }

      .step2 {
        svg {
          #right {
            g.notfound {
              rect {
                opacity: 1;
              }
            }
          }
        }
      }
    }

    .progress-found {
      div {
        display: inline-block;
        background-color: rgba(#20323f, 0.2);
        margin: 0 5px;
        width: 20px;
        height: 20px;
        border-radius: 20px;
        position: relative;

        &.found {
          background-color: rgba(#20323f, 1);
          &:before {
            content: "";
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background: url("/assets/images/games/skrivnostne-razlike/icon-star.png");
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

@keyframes anim-effect {
  0% {
    transform: scale3d(0.3, 0.3, 1);
  }
  25%,
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1);
  }
}

@keyframes anim-effect-found {
  0% {
    transform: scale3d(0.3, 0.3, 1);
  }
  25%,
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(1.2, 1.2, 1);
  }
}
