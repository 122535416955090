.nagrade {
  img {
    width: 100%;
    @include media-breakpoint-down(sm) {
      width: 70%;
    }
  }
  .close {
    cursor: pointer;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 30px;
    top: 50px;
  }
}
