@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Book.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Book.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Semibold.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontPro/LidlFontPro-Bold.woff2") format("woff2"),
    url("../fonts/LidlFontPro/LidlFontPro-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Lidl";
  src: url("../fonts/LidlFontScriptPro/LidlFontScriptPro.woff2") format("woff2"),
    url("../fonts/LidlFontScriptPro/LidlFontScriptPro.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@import "./variables.scss";
@import "./global.scss";
@import "../../views/OptIn/OptIn.scss";
@import "../../views/Game/Games.scss";
@import "../../views/Game/Success/Success.scss";
@import "../../views/Nagrade/Nagrade.scss";
@import "../../views/Game/Games/SkrivnostneRazlike/SkrivnostneRazlike.scss";
@import "../../views/Game/Games/ZacaranSpomin/ZacaranSpomin.scss";
@import "../../views/Game/Games/CarobniMagneti/CarobniMagneti.scss";
