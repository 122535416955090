.games {
  svg {
    z-index: 1;
    position: relative;
  }

  svg.inactive {
    transform: scale(0.75);
  }
  .inactive_link {
    //opacity: 0.7;
    pointer-events: none;
  }

  .active {
    transition: all 0.5s ease;
    animation: floatWithShadow 6s ease-in-out infinite;
    animation-delay: 0.5s;
    filter: drop-shadow(0px 0px 41px #e4c418);
    #kljuc {
      transform-origin: center;
      transition: all 0.2s ease-in-out;
    }
    &:hover {
      transform: scale(1.1);
      animation-play-state: paused;
      filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
      #kljuc {
        transform: scale(1.2);
      }
    }

    &:active {
      transform: scale(1.05);
    }
  }

  .nav-shield {
    position: relative;
    margin-bottom: 70px;
    @include media-breakpoint-up(md) {
      position: absolute;
      margin-bottom: 0;
    }

    svg {
      width: 250px;
      @include media-breakpoint-down(md) {
        width: 150px;
        height: auto;
      }
    }

    &.first {
      @include media-breakpoint-up(md) {
        bottom: 10%;
        left: 10%;
      }
    }

    &.second {
      @include media-breakpoint-up(md) {
        top: 0;
        left: 0;
        right: 0;
      }
    }

    &.third {
      @include media-breakpoint-up(md) {
        right: 10%;
        bottom: 10%;
      }
    }

    .textbox {
      background-image: url("/assets/images/shield_txt_bg.png");
      background-repeat: no-repeat;
      width: 200px;
      height: 106px;

      position: absolute;
      top: calc(100% - 100px);
      left: 50%;
      transform: translateX(-55%);
      z-index: 0;

      @include media-breakpoint-down(md) {
        transform: translate(-50%, -27%) scale(0.76);
        top: calc(100% - 10px);
      }

      h3 {
        font-size: 1.5rem;
        position: absolute;
        color: $blue;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          font-size: 1.3rem;
        }
      }
    }
  }
}
