.carobni-magneti {
  .drags,
  .drops {
    @include media-breakpoint-down(sm) {
      width: 50%;
      display: inline-block;
    }
  }
  .drops {
    .item {
      display: inline-block;

      .droptarget {
        border: 2px solid rgba($lidlblue, 0.7);
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        border-radius: 5px;
        display: inline-block;
        width: 100px;
        height: 100px;
        margin: 10px;
        padding: 10px;
        @include media-breakpoint-down(sm) {
          width: 80px;
          height: 80px;
        }

        img {
          height: 100%;
        }
      }

      &.found {
        .droptarget {
          border: 3px solid green;
        }
      }
    }
  }

  .ddcontainer {
    border: 2px dashed $lidlblue;
    border-radius: 5px;
    width: 100px;
    height: 100px;
    margin: 10px;

    @include media-breakpoint-down(sm) {
      width: 80px;
      height: 80px;
    }

    > span {
      width: 100%;
      height: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}
