// Colors

$violet: #612a71;
$blue: #20323f;
$white: #fff;
$black: #000;
$lidlblue: #0050aa;

$font-family-sans-serif: "Lidl";
$primary: $violet;
$secondary: $blue;

$headings-font-family: "Lidl";
$headings-color: $violet;

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 5rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);

$theme-colors: (
  "primary": $violet,
  "secondary": $blue,
  "blue": $blue,
  "white": $white,
  "lidlblue": $lidlblue,
);

$headings-font-weight: 700;
$headings-line-height: 1;
$h1-font-size: 3.5rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;

$btn-focus-box-shadow: 0;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 1.2rem;
$btn-padding-x: 2rem;
$btn-padding-y: 1rem;
$btn-border-width: 3px;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$border-radius: 7px;
$border-radius-sm: 7px;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $blue;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-btn-font-size-sm: 0.8rem;
$input-border-color: $blue;
$input-border-width: 3px;
$input-focus-border-color: $blue;
$input-color: $blue;
//$form-floating-label-opacity: 0.5;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.5rem;
$form-check-input-width: 1.5em;
$form-check-input-border: 3px solid $blue;
$form-check-input-border-radius: 50%;

$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
