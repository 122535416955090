//Disable landscape
#turn {
  display: none;
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $black;
  color: $white;
}

@media only screen and (min-device-width: 380px) and (max-device-width: 900px) and (orientation: landscape) {
  #turn {
    display: block;
    h2 {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      margin-bottom: 0;
    }
  }
}

.dev-buttons {
  display: none;
}

//Global styles

body {
  background-color: black;
  overflow: hidden;
  @include media-breakpoint-down(sm) {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

body,
html {
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

// Typography

p {
  @include media-breakpoint-down(sm) {
    font-size: 0.85rem;
  }
}

a {
  text-decoration: none;
  outline: none;
}

a:active,
a:focus {
  outline: 0;
  border: none;
}

h1.huge {
  font-size: 8rem;
  font-weight: 700;
  line-height: 1;
  @include media-breakpoint-down(lg) {
    font-size: 6rem;
  }
  @include media-breakpoint-down(sm) {
    font-size: 2.5rem;
  }
}

.headline-star-container {
  overflow: hidden;
  margin-bottom: 10px;
  .heading-star {
    line-height: 1;
    display: inline-block;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 55%;
    }

    .star {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .star-left {
      right: 100%;
    }
    .star-right {
      left: 100%;
    }

    &__big {
      .star {
        height: 150px;
        width: 150px;
        @include media-breakpoint-down(sm) {
          width: 100px;
          height: 100px;
        }
      }
    }

    svg {
      transform-origin: center;
    }

    path {
      transform-origin: center;
    }

    @for $i from 0 through 8 {
      path:nth-child(#{$i}) {
        animation: blink 3s ease-in-out infinite;
        animation-delay: $i / 2 + s;
      }
    }
  }
}

p.lead {
  font-weight: 600;
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.text-texture {
  background-image: url("/assets/images/gold-texture-small.jpg");
  background-clip: text;
  color: transparent;
}

.text-shadow {
  //text-shadow: 3px -2px 2px rgba(150, 150, 150, 1);
  filter: drop-shadow(6px -5px 0px #000);
  @include media-breakpoint-down(sm) {
    filter: drop-shadow(3px -3px 0px #000);
  }
}

.heading-status {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
  .heading {
    h2 {
      @include media-breakpoint-down(sm) {
        font-size: 1rem;
      }
    }
  }

  .sound {
  }
}

// Buttons

.button-container {
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  z-index: 2;

  .btn {
    width: 100%;
  }
}

.btn {
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  transition: all 0.5s ease;
  &:hover {
    transform: scale(1.03);
  }

  &:active {
    transform: scale(1.01);
  }

  @include media-breakpoint-down(md) {
    padding: 10px 15px;
  }
}

.btn-texture {
  //background-image: url("/assets/images/gold-texture-small.jpg");
  background-color: $white;
  background-size: 100%;
  background-position: center;
  transition: all 0.5s ease;
  color: $lidlblue;
  border-color: transparent;
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.4));

  &:hover {
    border-color: transparent;
    color: $lidlblue;
  }
}

// Intro screen
.hero {
  .sidebar {
    z-index: 1;
    width: 300px;
    height: 100%;
    position: fixed;
    transition: 0.5s all ease;
    background-color: transparent;
    background-image: url("/assets/images/bg_sidebar_main.png");
    background-size: 100%;
    background-position: center;

    @include media-breakpoint-down(md) {
      position: relative;
      display: block;
      width: 100%;
      background-image: none;
    }

    .logo-primary {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      padding-right: 25px;

      img {
        width: 160px;
        display: block;
        margin: 0 auto;
      }
    }

    .logo-secondary {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-right: 25px;
      @include media-breakpoint-down(md) {
        position: relative;
        padding-right: 0;
        padding-top: 5px;
      }

      p {
        font-size: 9px;
        color: #9ea3ad;

        @include media-breakpoint-down(sm) {
          font-size: 8px;
        }
      }

      img {
        @include media-breakpoint-down(sm) {
          height: 30px;
        }
      }
    }

    .bg-sidebar,
    .bg-sidebar-mobile {
      position: absolute;
      bottom: 0;
      left: 0;
      min-width: 100%;
      min-height: 100%;
      z-index: 0;
    }
  }

  .main {
    width: calc(100% - 270px);
    height: 100vh;
    margin-left: 270px;
    padding-bottom: 40px;
    position: relative;
    background: url(/assets/images/bg_home.jpg) no-repeat top center;
    background-size: cover;

    @include media-breakpoint-down(md) {
      width: 100%;
      margin-left: 0;
      background: url(/assets/images/bg_home_mobile.jpg) no-repeat top center;
      background-size: 140%;
      background-color: #000;
    }

    h1.huge {
      margin-top: 12rem;
      @include media-breakpoint-down(sm) {
        margin-top: 7rem;
      }

      @media (max-width: 359px) {
        margin-top: 12rem;
      }
    }
  }
}

.messages {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);

  background: url(/assets/images/bg01.jpg) no-repeat top center;
  background-size: cover;

  h2 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }

  &.games {
    background: url(/assets/images/bg_games.jpg) no-repeat bottom center;
    background-size: cover;
  }

  &.success {
    background: url(/assets/images/bg_success.jpg) no-repeat bottom center;
    background-size: cover;
  }

  &.skrivnostnerazlike {
    background: url(/assets/images/bg_skrivnostnerazlike.jpg) no-repeat bottom
      center;
    background-size: cover;
  }

  &.carobnimagneti {
    background: url(/assets/images/bg_carobnimagneti.jpg) no-repeat bottom
      center;
    background-size: cover;
  }

  &.success {
    background: url(/assets/images/bg_success.jpg) no-repeat bottom center;
    background-size: cover;
  }

  &.ingame {
    .wrapper {
      @include media-breakpoint-down(sm) {
        margin-top: 100px;
      }
    }
  }

  div.success {
    position: absolute;
    height: 180px;
    left: 0;
    right: 0;
    margin: auto;
    top: -80px;

    @include media-breakpoint-down(sm) {
      height: 150px;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  .bg-element {
    position: absolute;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      width: 70%;
    }

    &.skrivnostnerazlike {
      left: 20px;
      bottom: 20px;
      @include media-breakpoint-down(sm) {
        width: 50%;
        left: -30px;
        bottom: 12px;
      }
    }

    &.zacaranspomin {
      left: 0;
      bottom: 0;
    }

    &.carobnimagneti {
      right: 0;
      bottom: 0;
    }
  }
}

// Wrappers

.wrapper {
  .bg-top {
    background-image: url("/assets/images/bg_top.png");
    background-repeat: no-repeat;
    width: 100%;
    height: 80px;
    margin-bottom: -1px;
  }

  .bg-bottom {
    background-image: url("/assets/images/bg_bottom.png");
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 100px;
    margin-top: -1px;
  }

  &__narrow {
    max-width: 710px;
    margin: 0 auto;
    //height: 100vh;
    position: relative;
    @include media-breakpoint-down(sm) {
      height: 100vh;
    }

    &.wide {
      max-width: 960px;
    }

    .wrapper-inside {
      background-color: $white;
      height: calc(100% - 180px);
      overflow-x: hidden;
      padding: 0 20px;
      padding-top: 15px;
      @include media-breakpoint-down(sm) {
        height: 100%;
        padding: 0 10px;
      }
    }
  }

  &__full-width {
    position: relative;

    .wrapper-inside {
      background-color: $white;
      height: auto;
      overflow-x: hidden;

      .content {
        max-width: 710px;
        margin: 15px auto;

        @include media-breakpoint-down(sm) {
          padding: 0 20px;
          margin-bottom: 0;
        }
      }
    }
  }
}

.nagrade {
  .wrapper {
    &__narrow {
      height: 100vh;
    }
  }
}

//Subpages

.usps {
  img {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    height: 80px;
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
    animation-delay: 0.5s;
  }
  p {
    margin-bottom: 0;
  }
}

.ddcontainerghost {
  display: none;
}

// FORMS

.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 0rem 0.75rem;
}

.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3rem + 2px);
  line-height: 1;
}

.form-floating > label {
  padding: 0.5rem 0.75rem;
  font-size: 1.2rem;
}

// GAME STATUS

.game-status {
  position: absolute;
  width: 90%;
  left: 50%;
  top: 30px;
  transform: translateX(-50%);

  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 700;

  .round-time-bar {
    overflow: hidden;
    background-color: $white;
    border-radius: 5px;
  }
  .round-time-bar div {
    height: 10px;
    transition: background-color 1000ms linear;

    border-radius: 5px;

    &.red {
      background-color: red;
    }
  }

  &.zacaranspomin {
    background-color: rgba($primary, 0.5);
    .round-time-bar div {
      background-color: $primary;
    }
  }
  &.carobnimagneti {
    background-color: rgba(#0050aa, 0.5);
    .round-time-bar div {
      background-color: #0050aa;
    }
  }

  &.skrivnostnerazlike {
    background-color: rgba($blue, 0.5);
    .round-time-bar div {
      background-color: $blue;
    }
  }
}

.score-booster {
  position: absolute;
  opacity: 0;
  z-index: 2;
  width: 200px;
  height: 200px;
  line-height: 200px;
  font-size: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: rgba(220, 20, 60, 0.7);
  border-radius: 100%;
  color: white;
  font-weight: 600;
  font-size: 6rem;
  animation: scaleAnimation 0.5s ease-in-out;
  animation-iteration-count: 1;

  span {
    top: 50%;
    transform: translateY(-50%);
    line-height: 1;
  }
}

.player {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
}

.soundicon {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;

  path {
    fill: white;
  }
}

// ANIMATIONS

@keyframes float {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px);
  }
}

@keyframes floatWithShadow {
  0% {
    filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
    transform: translatey(0px);
  }
  50% {
    filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
    transform: translatey(-15px);
  }
  100% {
    filter: drop-shadow(0px 0px 41px rgba(228, 196, 24, 1));
    transform: translatey(0px);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  100% {
    opacity: 0;
    transform: scale(0.8);
  }
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(0) rotate(-0.5turn);
  }
  25% {
    opacity: 1;
    transform: scale(1);
  }
  75% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0) rotate(-0.5turn);
  }
}
