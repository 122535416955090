.optin {
  .form-group {
    .input-group {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: $input-border-radius;

      .form-control {
        font-weight: 400;
      }
    }

    .big-label {
      font-size: 1.25rem;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.7);
      @include media-breakpoint-down(sm) {
        font-size: 0.9rem;
      }
    }

    label {
      font-size: 0.8rem;
    }
  }
  img.klobuk {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    height: 80px;
    transform: translatey(0px);
    animation: floatHat 6s ease-in-out infinite;
    animation-delay: 0.5s;
    position: absolute;
    top: 45px;
    left: 50%;
    transform: translateX(-50%);
    @include media-breakpoint-down(sm) {
    }
  }
}

@keyframes floatHat {
  0% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px) translateX(-50%);
  }
  50% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.2));
    transform: translatey(-15px) translateX(-50%);
  }
  100% {
    filter: drop-shadow(8px 8px 7px rgba(0, 0, 0, 0.4));
    transform: translatey(0px) translateX(-50%);
  }
}
